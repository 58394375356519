<script setup lang="ts">
const localePath = useLocalePath();
const appStore = useAppStore();
</script>

<template>
  <DashboardSideMenuDefault>
    <el-menu-item-group>
      <el-menu-item :index="localePath('backoffice-nutrient')">
        <el-icon>
          <BaseIcon icon="material-symbols:diamond-outline" size="20" />
        </el-icon>
        <template #title> Nutriments </template>
      </el-menu-item>

      <el-menu-item-group>
        <template #title v-if="!appStore.isSidePanelCollapsed"
          ><span>Aliments / Produits / Recettes</span></template
        >
        <el-menu-item :index="localePath('backoffice-createFood')">
          <el-icon>
            <BaseIcon icon="material-symbols:ev-shadow-add-outline" size="20" />
          </el-icon>
          <template #title> Créer un nouvel aliment</template>
        </el-menu-item>

        <el-menu-item :index="localePath('backoffice-basefoods')">
          <el-icon>
            <BaseIcon icon="mdi:food-apple" size="20" />
          </el-icon>
          <template #title> Aliments de base </template>
        </el-menu-item>
        <el-menu-item :index="localePath('backoffice-commercialfoods')">
          <el-icon>
            <BaseIcon icon="material-symbols:fastfood" size="20" />
          </el-icon>
          <template #title> Produits du commerce</template>
        </el-menu-item>
        <el-menu-item :index="localePath('backoffice-recipefoods')">
          <el-icon>
            <BaseIcon icon="mdi:chef-hat" size="20" />
          </el-icon>
          <template #title> Recettes</template>
        </el-menu-item>
      </el-menu-item-group>

      <el-menu-item-group>
        <template #title v-if="!appStore.isSidePanelCollapsed"
          ><span>Familles / Marques / Portions</span></template
        >

        <el-menu-item
          :index="`${localePath('backoffice-foodfamily')}?type=base`"
        >
          <el-icon>
            <BaseIcon icon="iconamoon:category-fill" size="20" />
          </el-icon>
          <template #title> Familles / Sous-familles</template>
        </el-menu-item>

        <el-menu-item
          :index="`${localePath('backoffice-foodfamily')}?type=recipe`"
        >
          <el-icon>
            <BaseIcon
              icon="material-symbols:book-2-outline"
              size="20"
              color="white"
            />
          </el-icon>
          <template #title> Types de Recettes </template>
        </el-menu-item>

        <el-menu-item :index="`${localePath('backoffice-brands')}`">
          <el-icon>
            <BaseIcon
              icon="material-symbols:brand-family"
              size="20"
              color="white"
            />
          </el-icon>
          <template #title> Marques </template>
        </el-menu-item>

        <el-menu-item :index="localePath('backoffice-portions')">
          <el-icon>
            <BaseIcon icon="material-symbols:scale" size="20" />
          </el-icon>
          <template #title> Portions </template>
        </el-menu-item>
      </el-menu-item-group>
    </el-menu-item-group>
  </DashboardSideMenuDefault>
</template>
